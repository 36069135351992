import { FaCheck } from "react-icons/fa6";
import { OfferSteps } from "../../components/offer-steps";
import { useNavigate } from "react-router-dom";

export const OfferSentTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="font-bold text-2xl text-neutral-600 my-1 md:my-4">
        Send offer
      </div>
      <div className=" mt-3 md:mt-6 h-[72px] aspect-square rounded-full bg-green-600 flex items-center justify-center">
        <FaCheck size={34} color="white" />
      </div>
      <div className=" text-neutral-900 text-2xl md:text-4xl md:my-5 my-3 font-bold">
        Sent successfully
      </div>
      <div className=" text-neutral-600 text-base font-medium text-center max-w-[400px]">
        AB-FX offer was sent successfully and now awaiting signature. an email
        has been sent to the merchant to notify him.
      </div>
      <div className="my-4 w-full flex justify-center">
        <OfferSteps />
      </div>
      <div className=" max-w-[400px] w-full">
        <button
          onClick={() => navigate("/offer-generation")}
          className=" rounded py-2 bg-primary-500 text-white w-full max-w-[400px] text-sm"
        >
          Done
        </button>
      </div>
    </>
  );
};
