import { Table } from "@medusajs/ui";
import getDate from "../../../../core/utils/get-date";
import { GoEye } from "react-icons/go";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const MqrTable = ({ items }: any) => {
  const navigate = useNavigate();
  return (
    <Table className="">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3">
            Merchant
          </Table.HeaderCell>
          <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3">
            Submission date
          </Table.HeaderCell>
          {/* <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3">Date</Table.HeaderCell> */}
          <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3">
            Status
          </Table.HeaderCell>
          <Table.HeaderCell className=" font-normal text-neutral-500 border border-neutral-200 text-left px-3"></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body className=" ">
        {items &&
          items.map((item: any) => (
            <Table.Row key={item.id}>
              <Table.Cell className=" py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                {item.email}
              </Table.Cell>
              <Table.Cell className=" py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                {getDate(item.created_at)}
              </Table.Cell>
              <Table.Cell className=" py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                <span
                  className={` ${
                    item.email === "approved"
                      ? " bg-green-600"
                      : "bg-secondary-600"
                  } capitalize py-[2px] px-[10px] font-[600] rounded-full text-white text-xs `}
                >
                  {"Pending"}
                </span>
              </Table.Cell>
              <Table.Cell className=" text-center py-3 px-3 border border-neutral-200 text-neutral-900 text-sm">
                <Link
                  to={`mqr-details/${item.user_id}`}
                  className=" text-primary-500 flex items-center justify-end gap-x-1 "
                >
                  <GoEye size={13} className=" " />
                  <div>View</div>
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};
