import { useMutation } from "@tanstack/react-query";
import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";

export function useToggleFile() {
  return useMutation({
    mutationFn: async (id) =>
      client({
        url: `admin/files/${id}/toggle`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${getItem("auth")}`,
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log("error", error);
        }),
  });
}
