import { FaCheck } from "react-icons/fa6";

export const OfferSteps = () => {
  const offer_steps = [
    {
      id: 1,
      title: "MQR Approved",
      isDone: true,
    },
    {
      id: 2,
      title: "Offer Sent",
      isDone: true,
    },
    {
      id: 3,
      title: "Awaiting for merchant signature",
      isDone: false,
    },
    {
      id: 4,
      title: "KYB Review",
      isDone: false,
    },
  ];
  return (
    <div className=" max-w-[400px] bg-neutral-100 rounded w-full p-3 flex flex-col gap-y-2">
      {offer_steps.map((offer) => (
        <div key={offer.id} className="flex gap-x-2 items-center">
          <div
            className={` ${
              offer.isDone ? "bg-green-600 " : "bg-neutral-300"
            } flex items-center justify-center text-white w-6 h-6 rounded-full text-sm`}
          >
            {offer.isDone ? (
              <FaCheck size={16} color="white" />
            ) : (
              <div>{offer.id}</div>
            )}
          </div>

          <div
            className={` ${
              offer.isDone ? " text-green-600" : " text-neutral-600"
            } text-sm`}
          >
            {offer.title}
          </div>
        </div>
      ))}
    </div>
  );
};
