import { AiOutlineDownload, AiOutlineExclamationCircle } from "react-icons/ai";
import { DocumentCard } from "../../components/document-card";
import { FiCheckCircle } from "react-icons/fi";
import { DocumentModal } from "../../components/document-modal";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  useFiles,
  useToggleFile,
  useToggleKyb,
} from "../../../../services/api";
import { Spinner } from "../../../commun";

export const DocumentsListTemplate = () => {
  const { state } = useLocation();
  const [kybState, setKYBState] = useState<number>(state.merchant.kyc);
  const { mutateAsync, isPending } = useToggleFile();
  const { mutateAsync: asyncKybToggle, isPending: isPendingToggleKyb } =
    useToggleKyb();
  const {
    data: files,
    isLoading,
    isFetching,
    refetch,
    isSuccess,
  } = useFiles(state?.merchant.id);

  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const toggleState = async (id: any) => {
    await mutateAsync(id, {
      onSuccess: (response) => {
        refetch();
        setShowModal(false);
      },
      onError: (error) => {
        // console.log("from test test", error);
      },
    }).catch((error) => {});
  };

  const toggleKyb = async () => {
    await asyncKybToggle(state?.merchant.id, {
      onSuccess: (response) => {
        refetch();
        setKYBState(response.data.User.kyc);
        console.log(response);
      },
      onError: (error) => {
        // console.log("from test test", error);
      },
    }).catch((error) => {});
  };
  return (
    <>
      {isLoading ? <Spinner /> : <></>}
      <div className=" border border-neutral-200 p-3  rounded-lg">
        <div className="flex justify-between w-full">
          <div className="font-bold text-lg text-neutral-900">
            Submitted documents
          </div>
          <div className="flex w-10 h-10 rounded items-center justify-center border border-neutral-300">
            <AiOutlineDownload size={25} className=" text-neutral-700" />
          </div>
        </div>
        <div className="mt-5 xs:mt-10">
          <div className="flex flex-col gap-y-3">
            {files &&
              files.length > 0 &&
              files.map(
                (doc: any) =>
                  doc.file.category == "kyc" && (
                    <DocumentCard
                      setSelectedFile={(doc) => setSelectedFile(doc)}
                      key={doc.file.id}
                      document={doc}
                      setShowModal={() => setShowModal(true)}
                    />
                  )
              )}
          </div>
        </div>
        <div className="flex mt-8 gap-x-9">
          {files && files.length > 3 && (
            <>
              <button
                disabled={isPendingToggleKyb}
                onClick={toggleKyb}
                className=" bg-neutral-100 rounded flex items-center gap-x-1 px-5 py-2 text-neutral-600"
              >
                {isPendingToggleKyb ? (
                  <Spinner />
                ) : (
                  <>
                    {kybState == 0 ? (
                      <>
                        <FiCheckCircle size={15} />
                        Approve KYB
                      </>
                    ) : (
                      <>Decline KYB</>
                    )}
                  </>
                )}
              </button>
            </>
          )}

          {/* <button className=" bg-neutral-100 rounded flex items-center gap-x-1 px-5 py-2 text-neutral-600">
            <AiOutlineExclamationCircle size={17} />
            Request more info
          </button> */}
        </div>
        {showModal && (
          <DocumentModal
            isPending={isPending}
            title={selectedFile.file.name}
            state={selectedFile.file.state}
            onApprove={() => toggleState(selectedFile.file.id)}
            onCancel={() => setShowModal(false)}
          >
            {selectedFile.url.includes("pdf") ? (
              <Link
                target="_blank"
                to={selectedFile.url}
                className=" text-sm text-primary-500 font-bold w-full text-left"
              >
                Click to view
              </Link>
            ) : (
              <img src={selectedFile.url} alt={selectedFile.file.name} />
            )}
          </DocumentModal>
        )}
      </div>
    </>
  );
};
