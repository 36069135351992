import { StatisticsCard } from "../../components/statistics-card";
import { MerchantTable } from "../../components/merchants-table";
import { useEffect, useState } from "react";
import { MerchantModal } from "../../components/merchant-modal";
import { useUsers } from "../../../../services/api";
import { FilterSection } from "../../components/filter-section";
import { Spinner } from "../../../commun";
import getDate from "../../../../core/utils/get-date";

export const MerchantsTemplate = ({
  is_offer_generation,
  isKyb,
}: {
  is_offer_generation?: boolean;
  isKyb?: boolean;
}) => {
  const { data, isPending, isSuccess, isError } = useUsers();
  const [search, setSearch] = useState("");
  const [filteredData, setFiteredData] = useState<Array<any>>([]);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<Date | undefined>();

  useEffect(() => {
    if (data && data.length > 0) {
      if (is_offer_generation) {
        let newData = data.filter((user: any) => user.mqr);
        setFiteredData([...newData]);
      } else {
        setFiteredData([...data]);
      }
    }
    // data && data.length > 0 &&  setFiteredData([...data]);
  }, [data, isSuccess, isPending, is_offer_generation]);

  const filterData = (value: any) => {
    setSearch(value);
    let newFiltered = data?.filter((item: any) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFiteredData([...newFiltered]);
  };

  useEffect(() => {
    if (date) {
      let newDate = getDate(date);
      let newFiltered =
        data &&
        data.length > 0 &&
        data?.filter((item: any) => getDate(item.created_at) === newDate);
      setFiteredData([...newFiltered]);
    } else {
      data && data.length > 0 && setFiteredData([...data]);
    }
  }, [date]);
  const [selectedMarchant, setSelectedMarchant] = useState(null);

  const getMqrSubmission = (): number => {
    let result = data.filter((merchant: any) => merchant.mqr != null).length;
    return result;
  };
  const getOfferSigned = (): number => {
    let result = data.filter((merchant: any) => merchant.kyc != 0).length;
    // to be changed to offers instead of kyc
    return result;
  };
  return (
    <div>
      {isPending ? (
        <div className=" flex-1 h-[90vh] flex justify-center items-center">
          <Spinner />
        </div>
      ) : data && isSuccess && filteredData ? (
        <>
          {!is_offer_generation && !isKyb && (
            <div className="flex w-full justify-between gap-x-4 mb-5">
              <StatisticsCard title={"Total merchant"} value={data.length} />
              <StatisticsCard
                title={"MQR Submission"}
                value={getMqrSubmission()}
              />
              <StatisticsCard title={"Offer signed"} value={getOfferSigned()} />
            </div>
          )}

          <FilterSection
            date={date}
            setDate={setDate}
            search={search}
            filterData={(e) => filterData(e)}
          />
          <div className="mt-4 md:w-auto w-full overflow-x-auto x-global-bg py-2 md:py-0">
            <MerchantTable
              is_offer_generation={is_offer_generation}
              isKyb={isKyb}
              items={filteredData}
              open={(merchant: any) => {
                setOpen(true);
                setSelectedMarchant(merchant);
              }}
            />
          </div>
        </>
      ) : (
        <></>
      )}

      {open && (
        <MerchantModal
          merchant={selectedMarchant}
          close={() => setOpen(false)}
        />
      )}
    </div>
  );
};
