import { Link } from "react-router-dom";
import { Offer } from "../../../../core/types";
import getDate from "../../../../core/utils/get-date";
import { useFiles } from "../../../../services/api";
import { useEffect, useState } from "react";
import { GoEye } from "react-icons/go";

export const OfferDetailsTemplate = ({
  offer,
  merchant_name,
}: {
  offer: Offer;
  merchant_name: string;
}) => {
  const { data, isPending, isSuccess } = useFiles(offer.user_id);
  const [signedDoc, setSignedDoc] = useState<string>("");

  useEffect(() => {
    let docs = null;
    if (isSuccess && data.length > 0) {
      docs = data.filter((file: any) => file.file.category == "document");
    }
    docs && docs[0] && setSignedDoc(docs[0].url);
  }, [data, isPending]);
  return (
    <div className=" flex w-full justify-center py-5">
      <div className="flex flex-col w-full max-w-[440px] gap-y-1">
        <div className="py-1">
          <div className=" bg-primary-500 py-1 rounded-t-lg text-white  font-semibold text-xl text-center">
            {merchant_name}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            eversign status
          </div>
          <div
            className={`${
              offer.eversign_status == "document_signed"
                ? " text-primary-500"
                : " text-secondary-500"
            }  text-sm capitalize`}
          >
            {offer.eversign_status == "document_signed" ? (
              <Link
                target="_blank"
                to={signedDoc}
                className=" flex items-center gap-x-2 "
              >
                Document Signed
                <GoEye />
              </Link>
            ) : (
              "document sent"
            )}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">Date</div>
          <div className={` text-sm text-neutral-600`}>
            {getDate(offer.created_at)}
          </div>
        </div>
        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">Type</div>
          <div className={` text-sm text-neutral-600`}>{offer.type}</div>
        </div>

        <div className="py-1 mt-2">
          <div className=" bg-primary-500 py-1 rounded-t-lg text-white  font-semibold text-xl text-center">
            {"FTD Buy Rates Global Cards"}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Minimum Transaction
          </div>
          <div className={` text-sm text-neutral-600`}>
            {offer.global_min_transaction}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Maximum Transaction
          </div>
          <div className={` text-sm text-neutral-600`}>
            {offer.global_min_transaction}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Approved Transaction Fee
          </div>
          <div className={` text-sm text-neutral-600`}>
            ${offer.approval_fee}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Declined Transaction Fee
          </div>
          <div className={` text-sm text-neutral-600`}>
            ${offer.failure_fee}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Refund Fee
          </div>
          <div className={` text-sm text-neutral-600`}>${offer.refund_fee}</div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Chargeback Fee
          </div>
          <div className={` text-sm text-neutral-600`}>
            ${offer.chargeback_fee}
          </div>
        </div>

        <div className="p-1 flex justify-between items-center">
          <div className=" text-neutral-900 text-base capitalize">
            Daily Limit
          </div>
          <div className={` text-sm text-neutral-600`}>
            ${offer.daily_limit}
          </div>
        </div>
      </div>
    </div>
  );
};
